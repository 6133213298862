import clsx from 'clsx';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IRawMasterdataTable } from '../../../../../shared/helpers/converters/masterdata.ts';
import { LinkText } from '../../../../../shared/helpers/HelperComponents.tsx';
import { extendedSearch, globalFuseOptions } from '../../../../../shared/helpers/helpers.ts';
import { useSelector } from '../../../../../shared/store/store.ts';
import p from '../../../../../shared/styles/component/admin/admin-pages/admin-page.module.scss';
import s from '../../../../../shared/styles/component/admin/admin-section.module.scss';
import AdminItemRow from '../../../components/AdminItemRow.tsx';
import FormSection from '../../../components/form/FormSection.tsx';

const fuseOptions = {
  ...globalFuseOptions,
  keys: ['name'],
};

const AdminInboxesMasterdata: React.FC = () => {
  const inboxMasterdataTables = useSelector((state) => state.admin.inboxMasterdataTables);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fuseData, setFuseData] = useState([]);
  const [searchResults, setSearchResults] = useState<IRawMasterdataTable[]>(null);
  const fuse = new Fuse(fuseData, fuseOptions);

  const handleMatches = (input) => {
    setSearchResults(extendedSearch(input, fuse));
  };

  const handleInput = (value) => {
    if (value === '') {
      setSearchResults(inboxMasterdataTables);
    } else {
      handleMatches(value);
    }
  };

  useEffect(() => {
    if (inboxMasterdataTables) {
      setSearchResults(inboxMasterdataTables);
      setFuseData(inboxMasterdataTables);
    }
  }, [inboxMasterdataTables]);

  return (
    <div className={clsx(s.form_body, s.form_body_scroll)}>
      <div className={p.body_header}>
        <h2>{t('admin:inboxes.masterdata')}</h2>
      </div>
      <p className={p.body_description}>
        <Trans
          i18nKey={'admin:inboxes.masterdataDescription'}
          components={{
            1: <LinkText to={'https://docs.paperbox.ai'} title={'Paperbox Docs'} />,
          }}
        />
      </p>
      <FormSection
        noStyle
        scroll
        title={t('admin:inboxes.masterdata')}
        add={{
          testId: 'masterdata-add',
          onClick: () => navigate('new'),
          label: t('admin:inboxes.sections.addNew'),
        }}
        search={{
          onChange: (e) => handleInput(e),
          placeholder: t('admin:inboxes.sections.masterdataSearch'),
        }}
        copy={{
          copyValues: searchResults?.map((setting) => setting.id),
        }}
      >
        <div className={clsx(s.row_list)}>
          {searchResults &&
            searchResults.map((setting, index) => {
              return (
                <AdminItemRow
                  animationSettings={{
                    enabled: true,
                    delay: 50 + index * 25,
                  }}
                  // handleDelete={() => handleDelete(setting)}
                  handleNav={() => {
                    navigate(setting.id);
                  }}
                  key={'masterdata' + setting.id}
                  masterdataTable={setting}
                  title={setting.name}
                />
              );
            })}
          {searchResults?.length === 0 && (
            <div className={s.no_results}>{t('admin:inboxes.sections.noMasterdataFound')}</div>
          )}
        </div>
      </FormSection>
    </div>
  );
};
export default AdminInboxesMasterdata;
